/* IMPACTO page */

#impacto{
    background-color: #405e32e9;
    height: 1700px; 
}

.poster{
    margin: 0 21%;
    display: flex;
    max-width: 100%;
    height: auto;
  }

#impacto h2{
    padding-top: 5%;
}  

#impacto h2,
#impacto h3{
  color: white;
  padding-bottom: 25px;
  text-align: center;
}

#impacto p{
    padding-top: 2px;
    padding-bottom: 10px;
    margin: 0 7%;
    text-align: left;
    color: white;
  }

  #impacto p{
    padding: 2% 14%;
  }

  #impacto a{
    color: white;
    text-decoration: underline;
}
#impacto a:hover{
    color: grey;
}

#impacto .row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 12%;
    padding-right: 5%;
  }

#impacto .container.text{
    display: block;
}

.container.impacto.row {
    padding: 3% 0;
    display: flex;
    flex-wrap: wrap;
}

#impacto .container.impacto iframe{
    /* margin: 5px 30px; */
    display: block;
    border-radius: 15px;
    /* max-width: 100%; */
}

#impacto .col {
  display: block;
  float: left;
  margin: 1% 0 1% 5%;
}

  #impacto .span-1-of-2 {
      width: 45%;
  }

#impacto .container.impacto iframe:first-of-type{
    margin-left: -5px;
}

.container.impacto.video{
  margin: 0 21%;
  padding-bottom: 15%;
} 

#impacto .container.impacto iframe{ 
    border-radius: 0px;
  }


@media only screen and (max-width: 1920px) and (min-width: 1680px){
    #impacto .span-1-of-2 { width: 40%;}
}

@media only screen and (max-width: 1336px){
    .poster{
        margin: 0 13%;
    }
}

@media only screen and (max-width: 1024px){
    
    #impacto{ height: 1500px;}

    #impacto .container.impacto iframe:first-of-type{ margin-left: 15%;}

    #impacto .container.impacto iframe{ 
        width: 50%;
        margin: 1% 15%;
    }

    .poster{
        margin: 0 18%;
        width: 80%;
    }

      .container.impacto.video{ margin: 0 16%; }
}

@media only screen and (max-width: 869px){

    #impacto{ height: 3150px; }

    #impacto h2{ padding-top: 15%;}

    #impacto .col.span-1-of-2{ width: 100%; }

    #impacto .soundcloud-grid{ margin: 5% 2% 5% 12%; }

    #impacto .container.impacto iframe{ 
        width: 100%;
        margin: 1% 0;
    }

    .poster{
        margin: 0 4%;
        width: 80%;
    }
    
    .container.impacto.video{ margin: 0 8%; }
        
}

@media only screen and (max-width: 768px){

    #impacto{ height: 2800px;}

    #impacto .container.impacto iframe:first-of-type{ margin-left: 9%;}

    .poster {
        margin: 0 14%;
        display: flex;
        max-width: 88%;
        height: auto;
    }

    #impacto .col.span-1-of-2 {width: 80%;}

    #impacto .soundcloud-grid{ margin: 5% 2% 5% 16%; }

    #impacto .video.col{ margin-left: 11%;}
    #impacto .video.col:nth-of-type(2) { margin-left:11%;}
}

@media only screen and (max-width: 500px){

    #impacto{ height: 2350px;}

    .poster {
        margin: 0 14%;
        display: flex;
        max-width: 80%;
        height: auto;
    }

    #impacto .soundcloud-grid{ margin: 5% 2% 5% 16%; }

    #impacto p{
        margin: 0 12%;
        font-size: 15px;
    }
    .container.impacto.video{ padding: 0 8%;}

    #impacto .fb-video{ margin: 0; }
}

@media only screen and (max-width: 414px){

    #impacto .container.impacto iframe{ 
        width: 50%;
        margin: 5% 15%;
    }
}