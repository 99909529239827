/*Mobile Nav Button*/
.mobile-nav-button {
    display: block;
    top: -3%; 
    left: 90%;
    background-color: white;
    position: fixed;
    z-index: 1;
    border-radius: 1px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  
  .mobile-nav-button:focus{
    outline: none;
  }
  
  /*MOB NAV BUTTON STRIPES*/

  .mobile-nav-button .bar{
    display: block;
    position: absolute;
    height: 2px;
    width: 75%;
    background: green;
    opacity: 1;
    left: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .mobile-nav-button .bar:nth-child(1) {
    top: 12px;
  }
  
  .mobile-nav-button .bar:nth-child(2) {
    top: 21px;
  }
  
  .mobile-nav-button .bar:nth-child(3) {
    top: 30px;
  }
  .mobile-nav-button:hover {
      background-color: darkslategrey;
  }
  .mobile-nav-button:hover .bar {
    background-color: lightgreen;
  }
 
  .mobile-nav-button.open .bar:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  .mobile-nav-button.open .bar:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .mobile-nav-button.open .bar:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  

  @media only screen and (max-width: 1920px){
    .mobile-nav-button{display: none;}
  }

  @media only screen and (max-width: 800px){
    .mobile-nav-button{display: flex;}
    .mobile-nav-button i{ 
      padding: 2%;
      margin-left: 14%;
      margin-bottom: 15%;
    }
  }

  @media only screen and (max-width: 768px){
    .mobile-nav-button{
      top: -2%;
      width: 44px; 
      height: 44px;
    }
  }


  @media only screen and (max-width: 624px){
    .mobile-nav-button{
        display: flex;
        top: 0%;
        left: 85%;
      }
      .mobile-nav-button i{ 
        padding: 2%;
        margin-left: 14%;
        margin-bottom: 15%;
        
      }
  }
  