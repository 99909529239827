/*TERMOS*/

.termos h5{
    padding-left: 20%;
    color: black;
}
.termos .first{
    margin-top: 65px;
}

.termos p{
    padding-top: 10px;
    padding-left: 20%;
    padding-bottom: 10px;
}

