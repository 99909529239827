/* Segment */
#segment {
    width: 100%;
}
#segment .container{
    display: flex;
    flex-direction: row;
}
.box-1{
    background-color: #e4edcd;
    padding: 2%;
    flex: 1;
    height: 400px;
}
.box-1:hover{
    background-color: white;
}

.text h2{
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    color: black;
    padding: 10% 30%;
}

.text h2:after{
    display:block;
    content: '';
    border-bottom: 1px solid black;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}

.text h2:hover:after{
    transform: scaleX(1);
    transform-origin:50% 100%;
    border-width: 50%;
}

.text p{
    color: black;
    font-size: 13px;
    font-weight: 300;
    padding: 2% 24%;
    text-align: center;
    text-justify: auto;
}

.box-2{
    flex:1.1;
}
.box-3{
    flex: 1.14;
}



@media only screen and (max-width: 869px){
    #segment .container{
        display: flex;
        flex-direction: column;
    }
    #segment .container .box-1 .text h2{
        font-family: 'Nunito', sans-serif;
        font-size: 18px;
        padding-top: 8%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    }

    #segment .container .box-1 .text p{
        font-size: 13.5px;
        font-weight: 300;
        padding: 2% 24%;
        text-align: center;
        text-justify: auto;
    }
}

@media only screen and (max-width: 768px){
    #segment .container{
        display: flex;
        flex-flow: column nowrap;
    }
    #segment .container .box-1 .text h2{
        font-family: 'Nunito', sans-serif;
        font-size: 18px;
        padding-top: 10%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    }

    #segment .container .box-1 .text p{
        font-size: 14px;
        font-weight: 300;
        padding: 2% 24%;
        text-align: center;
        text-justify: auto;
    }

    #segment .container .box-2{
        flex:1;
    }
    #segment .container .box-3{
        flex: 1;
    }

}

@media only screen and (max-width: 624px){
    #segment .container{
        display: flex;
        flex-flow: column nowrap;
    }
}

@media only screen and (max-width: 320px){
    #segment .container .box-1 .text h2{
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        padding-top: 10%;
        padding-bottom: 10%; 
        padding-left: 25%;
        padding-right: 25%
    }

    #segment .container .box-1 .text p{
        font-size: 13px;
        font-weight: 300;
        padding: 2% 24%;
        text-align: center;
        text-justify: auto;
    }
}
