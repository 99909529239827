/* Suturas Page */
#suturas{
    background-color: #405e32e9;
    height: auto; 
}

  .lans{
    margin: 0 22.5%;
    display: flex;
    max-width: 60%;
    height: auto;
  }

  #suturas h2{
      padding-top: 5%;
  }

  #suturas h3{
      text-align: center;
  }
  
  #suturas h2{
    color: white;
    padding-bottom: 25px;
    text-align: center;
  }
  
  #suturas p{
    padding-top: 2px;
    padding-bottom: 10px;
    margin: 0 7%;
    text-align: left;
    color: white;
  }

  #suturas a{
      color: white;
      text-decoration: underline;
  }

  #suturas a:hover{
    color: grey;
}
  
  #suturas .row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 12%;
    padding-right: 5%;
  }

  #suturas .span-1-of-2 { width: 50%;}

  #suturas .col{ margin: 1% 0 1% 0;}

  #showreel{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

  #suturas .row.soundcloud{
    padding-left: 28%;
  }

  

@media only screen and (max-width: 1920px) and (min-width: 1680px){

}

 @media only screen and (max-width: 1336px){

    }

  @media only screen and (max-width: 1024px){

    .lans{
        margin: 0 13%;
        display: flex;
        max-width: 70%;
        height: auto;
      }

 }


  @media only screen and (max-width: 768px){

    .lans{
        margin: 0 10%;
        display: flex;
        max-width: 70%;
        height: auto;
        }


    #suturas .span-1-of-2 { width: 100%;}
    #suturas .span-1-of-2:nth-of-type(2) { 
        margin-bottom: 85px;
    }
        
    #showreel{
        position: absolute;
        top: 15% !important;
        left: 10% !important;
        width: 70% !important;
        height: 100% !important;
    }

  }

  @media only screen and (max-width: 500px){

    #suturas h2{ padding: 5% 15%; }
    
    .lans{
        margin: 0 10%;
        display: flex;
        max-width: 70%;
        height: auto;
      }
}

@media only screen and (max-width: 414px){

    #suturas h3{ padding: 0 10%;}

}



