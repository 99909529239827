/*SEGMENT LONG*/

#segment-long{
    width: 100%;
}

#segment-long .container{
    display: flex;
}

.box-four{
    background-color: #405e32e9;
    color: rgb(233, 232, 232);
    padding: 2%;
    height: 450px;
    flex: 0.7;
    border: none;
}

.box-four:hover{
    background-color: #acc865;
    color: black;
}

.box-four .text h2{
    font-family: 'Nunito', sans-serif;
    color: rgb(233, 232, 232);
    font-size: 16px;
    padding: 10% 30%;
    font-weight: 300;
}
.box-four .text h2:after{
    display:block;
    content: '';
    border-bottom: 1px solid  rgb(233, 232, 232);
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}
.box-four .text p{
    color: rgb(233, 232, 232);
    font-size: 15px;
    font-weight: 300;
    padding: 2% 16%;
    text-align: center;
    text-justify: auto;
    line-height: 1.55;
}
.box-four:hover .text h2,
.box-four:hover .text p{
    color: black;
}
.box-five{
    flex: 1.55;
    height: 450px;
}

.box-five .flip{
    display:none;
    width: 100%;
}

.box-five:hover .flip{
    display:flex;
}


@media only screen and (max-width: 869px){
    #segment-long .container{
        display: flex;
        flex-direction: column;
    }
    #segment-long .container .box-four .text h2{
        font-size: 18px;
        padding-top: 8%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    } 
    #segment-long .container .box-four .text p{
        font-size: 13.5px;
        padding: 2% 24%;
        line-height: 1.55;
    } 
}

@media only screen and (max-width: 768px){
    #segment-long .container .box-four .text h2{
        font-size: 18px;
        padding-top: 10%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    } 
    #segment-long .container .box-four .text p{
        font-size: 14px;
        padding: 2% 24%;
        line-height: 1.55;
    }  
}

@media only screen and (max-width: 624px){

}

@media only screen and (max-width: 320px){
    #segment-long .container .box-four .text h2{
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        padding-top: 10%;
        padding-bottom: 10%; 
        padding-left: 25%;
        padding-right: 25%
    }

    #segment-long .container .box-four .text p{
        font-size: 13px;
        font-weight: 300;
        padding: 2% 24%;
        text-align: center;
        text-justify: auto;
    }
}