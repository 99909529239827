/*SEGMENT LONG*/

#segment-long-end{
    width: 100%;
}

#segment-long-end .container{
    display: flex;
}

.box-4{
    background-color: #e4edcd;
    padding: 2%;
    height: 450px;
    flex: 1;
}

.box-4:hover{
    background-color: white;
}

.text h2{
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    color: black;
    padding: 6%;
}

.text p{
    color: black;
    font-size: 13px;
    font-weight: 300;
    padding: 2% 8%;
    text-align: center;
}

.box-5{
    flex: 2.2;
    height: 450px;
}

.box-5 .flip{
    display:none;
    width: 100%;
}

.box-5:hover .flip{
    display:flex;
}

@media only screen and (max-width: 869px){
    #segment-long-end .container{
        display: flex;
        flex-direction: column;
    }
    #segment-long-end .container .box-4 .text h2{
        font-size: 18px;
        padding-top: 8%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    }
    #segment-long-end .container .box-4 .text p{
        font-size: 13.5px;
        padding: 2% 24%;
        line-height: 1.55;
    }
}

@media only screen and (max-width: 768px){
    #segment-long-end .container .box-4 .text h2{
        font-size: 18px;
        padding-top: 10%;
        padding-bottom: 5%; 
        padding-left: 25%;
        padding-right: 25%
    }
    #segment-long-end .container .box-4 .text p{
        font-size: 14px;
        padding: 2% 24%;
        line-height: 1.55;
    }
}

@media only screen and (max-width: 320px){
    #segment-long-end .container .box-4 .text h2{
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        padding-top: 10%;
        padding-bottom: 10%; 
        padding-left: 25%;
        padding-right: 25%
    }

    #segment-long-end .container .box-4 .text p{
        font-size: 13px;
        font-weight: 300;
        padding: 2% 24%;
        text-align: center;
        text-justify: auto;
    }
}