/*EVENTS LISTS &  CONTENT*/
  #eventos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
  }

  .container-title {
    margin: 2% 0;
  }

  #eventos .container-events-list {
    display: flex;
    flex-wrap:wrap;
    font-size: 14px;
    margin: auto;
  }

.container-events-list .item-one,
.container-events-list .item-two,
.container-events-list .item-three,
.container-events-list .item-four,
.container-events-list .item-five,
.container-events-list .item-six,
.container-events-list .item-seven {
      flex: 1;
}

.events-list li {
    margin: 5% 2%;
    list-style: none;
    font-size: 15px;
    padding: 10px;
    font-weight: 300;
    background-color: #d2e47a;
    border-radius: 20px;
    text-align: center;
  }
  #eventos .events-list li:hover{
    background-color: #456a0d;
    color: white;
  } 

  #eventos .events-list li i {
    margin-right: 10px;
    font-size: 13px;
    color: #6cbe16;
    
  }

  button{
    background-color: #ec4811;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 1%;
    margin-right: 15%;
    margin-top: 5%;
    width: 10%;
    float: right;
    align-self: left;
    font-size: 16px;
  }

  button i {
    padding: 2%;
  }

  button:hover{
    background-color: red;
    cursor: pointer;
  }

  .container-squares{
    display: flex;
  }

  .square-1,
  .square-2,
  .square-3,
  .square-4{
    height: 450px;
    flex:1;
    margin-top: 5%;
  }

  /* .square-1 .flip{
    display: none;
    width: 140%;
  }
  .square-2 .flip,
  .square-3 .flip{
    display: none;
    width: 100%;
  }

  .square-4 .flip{
    display: none;
    width: 190%;
  }

  .square-1:hover .flip,
  .square-2:hover .flip,
  .square-3:hover .flip,
  .square-4:hover .flip{
    display: flex;
  } */



  @media only screen and (max-width: 414px){

    #eventos .container-events-list {
      display: flex;
      flex-flow: column nowrap;
      font-size: 14px;
      margin: auto;
    }
   
    #eventos button{
      padding: 2%;
      margin: 10% 33%;
      width: 35%;
      float: right;
      font-size: 15px;
    }

    .container-squares{
      display: flex;
      flex-direction: column;
    }

    .square-1,
    .square-2,
    .square-3,
    .square-4{
      margin-top: 0;
    }
  }