/* SOCIAL MEDIA NAV*/
.vertical-nav {
    position: fixed;
    display: block;
    margin-left: 5%;
    margin-top: 11%;
    z-index: 2;
  }
  .vertical-nav ul {
    list-style: none;
  }
  .vertical-nav ion-icon {
    margin: 4px;
    color: #5c9f14;
    font-size: 30px;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
  }
  .vertical-nav ion-icon:hover {
    color: white;
    background-color: #5c9f14;
    padding: 4px;
  }
  .logo-youtube-outline{
    background-color: green;
  }


/*MEDIA QUERIES*/
  @media only screen and (max-width: 800px){
    body .vertical-nav{
      top: 13%;
    }
  
    body .vertical-nav ion-icon {
      font-size: 25px;
    }
  }


  @media only screen and (max-width: 414px){

    body .vertical-nav ion-icon{
      font-size: 22px;
    }
  }